/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require("react")
const { AFrameRenderer, Marker } = require("react-web-ar")
const journeySummaryImage = require("./src/images/happy.jpg")

exports.wrapRootElement = ({ element }) => {
  return (
    <AFrameRenderer arToolKit={{ sourceType: "webcam", debugUIEnabled: false }}>
      <Marker
        parameters={{
          preset: "pattern",
          type: "pattern",
          url:
            // "https://raw.githubusercontent.com/lbelfield/augmented-reality/master/src/components/trainTicket/train-ticket.patt"
            "https://raw.githubusercontent.com/rksquared/sravs-test/master/src/components/trainTicket/pattern-sravs.patt",
        }}
      >
        <a-assets-item img id="journeySummaryImage" src={journeySummaryImage} />
        <a-entity
          geometry="primitive: box; height: 1;"
          material="shader: flat; src: #journeySummaryImage"
        />
      </Marker>
      {element}
    </AFrameRenderer>
  )
}
